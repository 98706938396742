import { GlobalVars } from './global/jv_fr';
import DicoKeys from '@dk/jv_fr.json';

export const environment = {
  production: false,
  name: 'dev',
  path: {
    root: `https://dev.${GlobalVars.name}.fr`,
    img: `https://dev.${GlobalVars.name}.fr/assets/images/${GlobalVars.name}`,
    appRoot: 'app',
    auth: 'https://accounts.preprod.jobvitae.fr',
    api: 'https://api.dev.jobology.fr/graphql',
    api2: 'https://api.dev.jobology.fr',
    prod: `https://www.${GlobalVars.name}.fr`,
    blog: {
      url: 'https://www.cache.jobology.fr/wp-json/wp/v2/',
      tagId: 458
    }
  },
  GlobalVars: GlobalVars,
  DK: DicoKeys,
  gtmID: 'FAKE_ID'
};


